import React from 'react'
import ReactWebChat, {
  createDirectLine,
  createStore,
  createCognitiveServicesSpeechServicesPonyfillFactory
} from 'botframework-webchat'

import { v4 as uuidv4 } from 'uuid'

import './App.scss'
import logo from '../../assets/aquabot-logo.png'

const WEBCHAT_TOKEN = process.env.REACT_APP_WEBCHAT_TOKEN
const DIRECT_LINE_SPEECH_KEY = process.env.REACT_APP_DIRECT_LINE_SPEECH_KEY

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      directLine: null,
      store: null,
      adapters: null,
      userId: null,
      locale: 'es-ES',
      webchatStyles: {
        // General
        paddingRegular: '16px',
        avatarSize: '40px',
        showAvatarInGroup: 'sender',
        hideUploadButton: true,
        transcriptVisualKeyboardIndicatorColor: '#FF0000',
        primaryFont: '"AccionaRegular", Tahoma, sans-serif',
        // Bot
        bubbleBorderRadius: '10px',
        bubbleBorderColor: '#FFE5E5',
        botAvatarBackgroundColor: '#FFFFFF',
        botAvatarImage: logo,
        botAvatarInitials: 'AQ',
        // User
        bubbleFromUserBackground: '#FFE5E5',
        bubbleFromUserBorderRadius: '10px',
        bubbleFromUserBorderColor: '#FFE5E5',
        // Sendbox
        sendBoxButtonColor: '#000000',
        showSpokenText: true
      },
      sendTypingIndicator: true
    }
  }

  /**
   * When we receive some message from iframe parent, initialize webchat
   */
  initWebChat = (event) => {
    if (event.data.type === 'AQUABOT_INIT') {
      // Check if AQUABOT_INIT message is sent

      // Get conversationId from event message, which means that it was saved
      // to localStorage before
      const conversationId = event.data.conversationId
      const userId = event.data.userId
      const directLine = createDirectLine({
        token: WEBCHAT_TOKEN,
        conversationId,
        webSocket: false
      })

      const store = createStore({}, ({ dispatch }) => (next) => (action) => {
        const activity = action.payload?.activity

        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          // When connection is completed

          // Get conversationId from this connection.
          // If it was set before it will be the same, so nothing will change
          // If it was not set before, a new one will be generated
          const newConversationId = action.payload.directLine.conversationId

          if (window.location === window.parent.location) {
            // We are not inside an iframe, so save conversationId in localStorage
            localStorage.setItem('conversationId', newConversationId)
          }

          // Send conversationId to parent, who will save it in localStorage
          window.parent.postMessage(
            { type: 'AQUABOT_READY', conversationId: newConversationId },
            '*'
          )

          if (!conversationId) {
            // If we are starting a new conversation, request welcome message
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'welcome'
              }
            })
          }

          // Listen to message from parent window and send Control-info to bot
          window.addEventListener(
            'message',
            (event) => {
              if (event.data.type === 'CONTRO_INFO') {
                dispatch({
                  type: 'WEB_CHAT/SEND_EVENT',
                  payload: {
                    name: 'control-info',
                    value: event.data.value
                  }
                })
              }
            },
            false
          )
        } else if (action.type === 'DIRECT_LINE/QUEUE_INCOMING_ACTIVITY') {
          if (activity.type === 'message' && activity.from.role === 'user') {
            this.disableLastActivityButtons()
          }
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (
            activity.type === 'message' &&
            activity.inputHint === 'expectingInput'
          ) {
            this.removeLastExpectingActivity()
          }
        }

        return next(action)
      })

      this.setState({
        directLine,
        store,
        userId
      })
    }
  }

  disableLastActivityButtons = () => {
    const feed = document.querySelector('.webchat__basic-transcript')

    const activities = feed.querySelectorAll(
      '.webchat__basic-transcript__activity'
    )
    const lastActivities = [...activities].slice(-2)

    lastActivities.forEach((activity) => {
      activity.querySelectorAll('button').forEach((button) => {
        button.disabled = true
      })
    })
  }

  removeLastExpectingActivity = () => {
    const feed = document.querySelector('.webchat__basic-transcript')

    const activities = feed.querySelectorAll(
      '.webchat__basic-transcript__activity'
    )
    const lastActivities = [...activities].slice(-1)

    lastActivities.forEach((activity) => {
      if (activity.querySelector('.ac-adaptiveCard')) {
        activity.style.display = 'none'
      }
    })
  }

  async componentDidMount() {
    window.addEventListener('message', this.initWebChat)

    if (window.location === window.parent.location) {
      // We are not inside an iframe, so initialize standalone webchat
      const conversationId = localStorage.getItem('conversationId')
      let userId = localStorage.getItem('userId')
      if (!userId) {
        userId = uuidv4()
        localStorage.setItem('userId', userId)
      }
      window.postMessage({ type: 'AQUABOT_INIT', conversationId, userId }, '*')
    }
  }

  render() {
    const {
      directLine,
      store,
      userId,
      locale,
      webchatStyles,
      sendTypingIndicator
    } = this.state

    let webchat = ''
    if (directLine && store) {
      webchat = (
        <ReactWebChat
          directLine={directLine}
          store={store}
          webSpeechPonyfillFactory={createCognitiveServicesSpeechServicesPonyfillFactory(
            {
              credentials: {
                region: 'northeurope',
                subscriptionKey: DIRECT_LINE_SPEECH_KEY
              }
            }
          )}
          userId={userId}
          locale={locale}
          styleOptions={webchatStyles}
          sendTypingIndicator={sendTypingIndicator}
        />
      )
    }
    return <div className="app">{webchat}</div>
  }
}

export default App
